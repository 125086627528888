// 签署承诺函及委托付款函
<template>
  <div class="text">

    <!-- //中小担和财富资金 -->
    <span v-if="dictType !== '1'">
      <sz-input
        v-model="signatoryData"
        placeholder="请输入"
        :disabled="isDetail"
        class="textInput"/>
      签署【承诺函】、【委托付款函】：<br />
      (1)在融资期内，不与其他第三方机构就本次融资所质押的项目合同进行融资，也不得用与金地集团或其下属项目公司签订的合同向第三方申请融资<br />
      (2)如融资项目所对应回款账户余额不足以扣划利息、服务费、违约金、本金等的，<sz-input
        v-model="signatoryData"
        placeholder="请输入"
        :disabled="isDetail"
        class="textInput"/>承诺<sz-input
        v-model="factoringData"
        placeholder="请输入"
        :disabled="isDetail"
        class="textInput"/>可就其承建的金地集团任意项目项下的工程款中进行扣划、追索等，直至项下的借款全额清偿。<br />
      (3)承诺新增大额融资、民间借贷、股权出现重大变更、重大诉讼或仲裁（案件标的额根据不同公司情况确定相应标准)等事项时供应商及担保人须主动告知我司；如不告知，根据预判如因供应商新增大额融资、民间借贷、股权出现重大变更、重大诉讼或仲裁等事项对我司权益产生不利影响的，我司将有权提前到期保理融资服务，要求供应商就相关应收账款进行反转让并向资金方偿还包括不限于本金、利息及相关费用、违约金等<br />
      (4)承诺对所提供融资业务基础材料的真实性负责；如所提供融资业务基础材料存在伪造、虚假情形，我司将有权提前到期保理融资服务，要求供应商就相关应收账款进行反转让并向资金方偿还包括不限于本金、利息及相关费用、违约金，并保留对其法律责任的追究权利<br
    /></span>

    <span v-else>
      <sz-input
        v-model="signatoryData"
        placeholder="请输入"
        class="textInput"
        :disabled="isDetail"
      />签署【承诺函】、【委托付款函】：<br />
      (1)在融资期内，不与其他第三方机构就本次融资所质押的项目合同进行融资，也不得用与金地集团或其下属项目公司签订的合同向第三方申请融资<br />
      (2)<sz-input
        v-model="finContractInfoNamesData"
        placeholder="请输入"
        class="textInput"
        :disabled="isDetail"
      />的进度款、竣备款、结算款均作为还款账户余额不足时的资金补充，直至建设银行深圳分行【线上平台云贷借款合同】项下的借款全额清偿。<br />
      (3)承诺新增大额融资、民间借贷、股权出现重大变更、重大诉讼或仲裁(案件标的额根据不同公司情况确定相应标准)等事项时供应商及担保人须主动告知我司；如不告知，根据预判如因供应商新增大额融资、民间借贷、股权出现重大变更、重大诉讼或仲裁等事项对我司权益产生不利影响的，我司将有权提前到期保理融资服务，要求供应商就相关应收账款进行反转让并向资金方偿还包括不限于本金、利息及相关费用、违约金等<br />
      (4)承诺对所提供融资业务基础材料的真实性负责；如所提供融资业务基础材料存在伪造、虚假情形，我司将有权提前到期保理融资服务，要求供应商就相关应收账款进行反转让并向资金方偿还包括不限于本金、利息及相关费用、违约金，并保留对其法律责任的追究权利
    </span>
  </div>
</template>
// 建行 signatory finContractInfoNames

<script>
import SzInput from '@/components/input/sz-input/sz-input.vue'
export default {
  components: { SzInput },
  props: {
    dictType: String,
    signatory: String,
    factoring: String,
    finContractInfoNames: String,
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fromData: {}
    }
  },
  // 计算属性 类似于data概念
  computed: {
    signatoryData: {
      get () {
        return this.signatory
      },
      set (val) {
        this.$emit('update:signatory', val)
      }
    },
    factoringData: {
      get () {
        return this.factoring
      },
      set (val) {
        this.$emit('update:factoring', val)
      }
    },
    finContractInfoNamesData: {
      get () {
        return this.finContractInfoNames
      },
      set (val) {
        this.$emit('update:finContractInfoNames', val)
      }
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例)
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素)
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.textInput {
  width: 200px;
}
.text {
  padding: 10px 0;
  line-height: 30px;
}
</style>
